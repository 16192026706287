import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Bio.scss'

class Bio extends Component {

  componentDidMount = () => {
    document.body.classList.add('bioBody')
  }

  componentWillUnmount = () => {
    document.body.classList.remove('bioBody')
  }

  render() {
    return (
      <>
        <Container >
          <Row>
            <Col xs='auto' md={{ span: '4', offset: 4 }} className='rounded bioContent' style={{ marginTop: '10px',  padding: '10px' }}>

              <p>Long Gone Blond is a solo project from singer, songwriter, and producer Maggie Maki. She currently records in her home studio, sometimes with Dion Macellari (guitarist).</p>

              <p>Her newest release is The Hummingbird Goes Sideways, now available on all platforms (Jan 2025). Her previous release was Goes Her Own Way (Transglobal Underground Remix) in 2022, and Under the Hollywood Sign (Transglobal Underground Remix) in 2020. Her album Body was released in late 2018 (she calls it her &ldquo;cellular memoir&rdquo;). Streaming on all platforms, and available on <a href='https://longgoneblond.bandcamp.com/'><b>Bandcamp</b></a>.</p>

              <p>Her previous projects included <a href="https://open.spotify.com/artist/5DcvWABApz03GrJOEbJbQ0?si=0lMDKzA-STe-4Fh0ly9vOg" style={{ color: '#000', textDecoration: 'underline' }}><b>Josephine the Singer</b></a>. These songs were given a slight patina by Grundman Mastering.</p>
            </Col>
          </Row>

        <Row>
          <div style={{ width: '10px', height: '100px', display: 'block' }}>

          </div>
        </Row>
        </Container>

      </>
    )
  }

}

export default Bio
